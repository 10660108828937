import React, { useState, useEffect } from "react";
import { getUserPhotoURL } from "Services/firebase";
const state = React.createContext();



const Provider = (props) => {
    const { children } = props;
    const [chef, setChef] = useState(null);
    const [sideBarIsOpen, setSideBarIsOpen] = useState(false);
    const [transactionsLoaded, setTransactionsLoaded] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [transaction, setTransaction] = useState(null);
    const [channelsLoaded, setChannelsLoaded] = useState(false);
    const [client, setClient] = useState(null);
    const [channels, setChannels] = useState([]);
    const [channel, setChannel] = useState(null);

    useEffect(() => {
        if (client) {
            client.on("channelJoined", async function (channel) {
                if (channel.isPrivate && channel.uniqueName.split("-&-")[0]) {
                    try {
                        channel.userPhotoURL = await getUserPhotoURL(channel.uniqueName.split("-&-")[0]);
                        setChannels((prevState) => prevState.concat(channel));
                    } catch (error) {
                        console.log(error)
                    }
                }
            });
        }
    }, [client]);


    return (
        <state.Provider
            value={{
                state: {
                    chef,
                    sideBarIsOpen,
                    transactionsLoaded,
                    transactions,
                    transaction,
                    channelsLoaded,
                    client,
                    channels,
                    channel,
                },
                action: {
                    setChef: (chef) => setChef(chef),
                    setSideBarIsOpen: (bool) => setSideBarIsOpen(bool),
                    setTransactionsLoaded: (transactionsLoaded) => setTransactionsLoaded(transactionsLoaded),
                    setTransactions: (transactions) => setTransactions(transactions),
                    setTransaction: (transaction) => setTransaction(transaction),
                    setChannelsLoaded: (channelsLoaded) => setChannelsLoaded(channelsLoaded),
                    setClient: (client) => setClient(client),
                    setChannels: (channels) => setChannels(channels),
                    setChannel: (channel) => setChannel(channel),
                }
            }}
        >
            {children}
        </state.Provider>
    );

}


export default Provider;
export { state as consumer }