import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { use100vh } from 'react-div-100vh';
import Header from "Layout/Header/Header";
import SideBar from "Layout/SideBar/SideBar";
import Footer from "Layout/Footer/Footer";
import Logo from "Assets/logo.gif"
const useStyles = makeStyles((theme) => ({
    container: {
        margin: 0,
        padding: 0,
        width: "100vw",
        minWidth: "100vw",
        backgroundColor: "rgba(250, 250, 250, 1)",
        height: props => props.height,
        minHeight: props => props.height,
        overflowX: "hidden",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
    },
    reset: theme.mixins.toolbar,
    children: {
        position: "relative",
        flexGrow: 1,
        overflowX: "hidden",
        overflowY: "auto",
        padding: theme.spacing(0, 2, 0, 2),
        backgroundColor: "#fff",
        width: "100%",
        maxWidth: "576px",
        margin: "auto",
    },
    mockup: {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#FF3539",
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const Layout = (props) => {
    const height = use100vh();
    const [mockup, setMockup] = useState(true);
    const classes = useStyles({ height });
    const { children } = props;

    useEffect(() => {
        setMockup(true);
        setTimeout(() => setMockup(false), 2000)
    }, []);


    return (
        <>
            <div className={classes.container}>
                <Header />
                <SideBar />
                <div className={classes.reset}></div>
                <div className={classes.children}>
                    {children}
                </div>
                <Footer />
            </div>
            {mockup && (
                <div className={classes.mockup}>
                    <img alt="" src={Logo} style={{ width: "50%" }} />
                </div>
            )}
        </>
    );
}

export default Layout;