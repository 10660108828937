import React, { useEffect, useContext, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SendIcon from '@material-ui/icons/Send';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import chatBackground from "Assets/stover-background-transparent.png";
import { useHistory } from "react-router-dom";
import { consumer } from "State";
import Loading from "Components/Loading";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${chatBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    chatContent: {
        padding: "30px 10px 10px 10px"
    },
    chatHeaderContainer: {
        position: "relative",
        marginBottom: 0,
        backgroundColor: "white",
        borderBottom: "solid 1px #dedede",
        minHeight: "70px"
    },
    userPic: {
        width: 35,
        height: 35,
        backgroundColor: "red",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "100%",
        marginRight: 10,
    },
    stoverChatChefMessageContainer: {
        backgroundColor: "#EEEEEE",
        borderTopLeftRadius: "0px 0px",
        borderTopRightRadius: "20px 20px",
        borderBottomLeftRadius: "20px 20px",
        borderBottomRightRadius: "20px 20px",
        marginBottom: "10px",
        padding: "1px 10px",
        width: "75%",
        textAlign: "left",
    },
    stoverChatUserMessageContainer: {
        backgroundColor: "#EEEEEE",
        borderTopRightRadius: "0px 0px",
        borderTopLeftRadius: "20px 20px",
        borderBottomLeftRadius: "20px 20px",
        borderBottomRightRadius: "20px 20px",
        marginBottom: "10px",
        padding: "1px 10px",
        width: "75%",
        marginLeft: "auto",
        textAlign: "right"
    },
    stoverChatChefMessageHeader: {
        font: "normal normal bold 15px/18px Roboto",
        letterSpacing: "0px",
        color: "#1D1D1B",
    },
    stoverChatUserMessageHeader: {
        font: "normal normal bold 15px/18px Roboto",
        letterSpacing: "0px",
        color: "#FF3539"
    },
    chatMessageText: {
        font: "normal normal normal 15px/18px Roboto",
        letterSpacing: "0px",
        color: "#1D1D1B",
        paddingBottom: "5px"
    },
    textFieldContainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        border: "0.5px solid #dedede",
        borderRadius: "50px",
        overflow: "hidden",
        minHeight: "57px",
        marginBottom: 1
    },
    textField: {
        color: "black !important",
        backgroundColor: "#fff !important",
        borderRadius: 0,
        flexGrow: 1,
        '&.MuiOutlinedInput-root': {
            '& fieldset': {
                border: "none",
            },
            '&:hover fieldset': {
                border: "none",
            },
            '&.Mui-focused fieldset': {
                border: "none",
            },
        },
        "&::placeholder": {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: "#BDBDBD !important"
        },
        "&:-ms-input-placeholder": {
            /* Internet Explorer 10-11 */
            color: "#BDBDBD"
        },
        "&::-ms-input-placeholder": {
            /* Microsoft Edge */
            color: "#BDBDBD"
        }
    },
    fileImg: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        marginRight: "28px",
        marginLeft: "14px"
    },
    label: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        minWidth: "26.38px",
        height: "25px",
        borderRadius: "20px",
        marginRight: "28px"
    },
    file: {
        color: "white",
        width: "12px",
        height: "12px"
    },
    sendBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        minWidth: "32.84px",
        height: "25px",
        borderRadius: "20px",
        paddingLeft: "5px",
        marginRight: "14px"
    },
    send: {
        color: "white",
        width: "16.8px",
        height: "16.8px"
    }
}));





const Message = () => {
    const classes = useStyles();
    const history = useHistory();

    const scrollContent = useRef(null);
    const imageAttachment = useRef(null);
    const [chatSpinner, setChatSpinner] = useState(false);




    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [channel, setChannel] = useState(null);
    const [channelMessages, setChannelMessages] = useState(null);
    let [showChatImg, setShowChatImg] = useState(null);
    const { state } = useContext(consumer);

    useEffect(() => {
        setIsLoading(true);
        const channel = state.channel;
        (async () => {
            try {
                if (channel) {
                    setChannel(channel);
                    let messages = await channel.getMessages();
                    let enhanced = await Promise.all(
                        messages.items.map(async (message) => {
                            if (message.type === "media") {
                                message["mediaMsgUrl"] = await message.media.getContentTemporaryUrl();
                                return message;
                            }
                            return message;
                        })
                    );
                    setChannelMessages(enhanced);
                    setIsLoading(false);
                    setTimeout(() => scrollContent.current && scrollContent.current.scrollIntoView(), 2000);
                } else {
                    history.push(`/messages`);
                }
            } catch (error) {
                setIsLoading(false);
                setError(error.message);
            }
        })();
        return () => {
            if (channel) {
                if (channel.listenerCount("messageAdded")) {
                    channel.removeAllListeners();
                }
            }
        }
    }, [history, state.channel])

    useEffect(() => {
        if (channel && channel.listenerCount("messageAdded") <= 1) {
            channel.on("messageAdded", async function (message) {
                if (message.type === "media") {
                    message["mediaMsgUrl"] = await message.media.getContentTemporaryUrl();
                    setTimeout(() => scrollContent.current && scrollContent.current.scrollIntoView(), 1000);
                }
                setChannelMessages((prevState) => prevState.concat(message));
                setChatSpinner(false);
                scrollContent.current && scrollContent.current.scrollIntoView();
            });
        }
    }, [channel]);


    const handleMouseDown = (event) => {
        event.preventDefault();
    };


    return (
        <>
            <div className={classes.root}>
                <div className={classes.chatHeaderContainer}>
                    <p style={{ marginBottom: 0, marginTop: 5, textAlign: "center" }}>{channel && channel.attributes && (channel.attributes.userName ? channel.attributes.userName : channel.friendlyName)}</p>
                    <div
                        style={{
                            width: 70,
                            height: 70,
                            backgroundColor: "red",
                            backgroundImage: `url(${channel && channel.userPhotoURL})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            borderRadius: "100%",
                            marginRight: 10,
                            position: "absolute",
                            left: "calc(50% - 35px)"
                        }}
                    />
                </div>
                {isLoading ? (
                    <Loading three={true} />
                ) : (
                        <>
                            <div className={classes.chatContent} style={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden" }}>
                                {channelMessages && channelMessages.map && channelMessages.map((message, index) => {
                                    if (message.attributes.id !== state.chef.email) {
                                        if (message.type === "media") {
                                            return (
                                                <div key={index} className={classes.stoverChatChefMessageContainer}>
                                                    <div className={classes.stoverChatChefMessageHeader}>{message.attributes.creator}</div>
                                                    <div>
                                                        <img
                                                            alt=""
                                                            src={message.mediaMsgUrl}
                                                            style={{
                                                                width: "80%",
                                                                height: "auto",
                                                                marginBottom: !message.attributes.textMsg ? 30 : 0,
                                                            }}
                                                        />
                                                        {!!message.attributes.textMsg && (
                                                            <div className={classes.chatMessageText}>{message.attributes.textMsg}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={index} className={classes.stoverChatChefMessageContainer}>
                                                <div className={classes.stoverChatChefMessageHeader}>{message.attributes.creator}</div>
                                                <div className={classes.chatMessageText}>{message.body}</div>
                                            </div>
                                        );
                                    } else {
                                        if (message.type === "media") {
                                            return (
                                                <div key={index} className={classes.stoverChatUserMessageContainer}>
                                                    <div className={classes.stoverChatUserMessageHeader}>{message.attributes.creator}</div>
                                                    <div>
                                                        <img
                                                            alt=""
                                                            src={message.mediaMsgUrl}
                                                            style={{
                                                                width: "80%",
                                                                height: "auto",
                                                                marginBottom: !message.attributes.textMsg ? 30 : 0,
                                                            }}
                                                        />
                                                        {!!message.attributes.textMsg && (
                                                            <div className={classes.chatMessageText}>{message.attributes.textMsg}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={index} className={classes.stoverChatUserMessageContainer}>
                                                <div className={classes.stoverChatUserMessageHeader}>{message.attributes.creator}</div>
                                                <div className={classes.chatMessageText}>{message.body}</div>
                                            </div>
                                        );
                                    }
                                })}
                                {chatSpinner && (
                                    <div style={{ textAlign: "center", marginBottom: 40 }}>
                                        <CircularProgress color="inherit" thickness={5} size={28} />
                                    </div>
                                )}
                                <div ref={scrollContent}></div>
                            </div>
                            <div className={classes.textFieldContainer}>
                                <OutlinedInput
                                    id="password"
                                    autoComplete="off"
                                    className={classes.textField}
                                    placeholder="Enter Message"
                                    type='text'
                                    value={message}
                                    disabled={!channel}
                                    fullWidth={true}

                                    classes={{ focused: classes.focused }}
                                    onChange={(event) => setMessage(event.target.value)}
                                />
                                {showChatImg && <img alt="" className={classes.fileImg} src={showChatImg} />}
                                <label className={classes.label}>
                                    <InsertDriveFileIcon className={classes.file} />
                                    <input
                                        onChange={(_) => {
                                            if (imageAttachment.current.files[0]) {
                                                let reader = new FileReader();
                                                reader.onload = function (e) {
                                                    setShowChatImg(e.target.result);
                                                };
                                                reader.readAsDataURL(imageAttachment.current.files[0]);
                                            }
                                        }}
                                        ref={imageAttachment}
                                        accept="image/*"
                                        type="file"
                                        hidden
                                    />
                                </label>
                                <span
                                    className={classes.sendBtn}
                                    disabled={!channel}
                                    onClick={_ => {
                                        if (imageAttachment.current.files[0]) {
                                            setChatSpinner(true);
                                            for (let ind in imageAttachment.current.files) {
                                                if (typeof imageAttachment.current.files[ind] === "object") {
                                                    let formData = new FormData();
                                                    formData.append("file", imageAttachment.current.files[ind]);
                                                    channel && channel.sendMessage(formData, { textMsg: !!message ? message : "", id: state.chef.email, creator: `${state.chef.firstName} ${state.chef.lastName}` });
                                                }
                                            }
                                            imageAttachment.current.value = "";
                                            setShowChatImg(null);
                                            setMessage("");
                                        } else {
                                            if (!message) return false;
                                            channel && channel.sendMessage(message, { id: state.chef.email, creator: `${state.chef.firstName} ${state.chef.lastName}` });
                                            setMessage("");
                                        }
                                    }}
                                    onMouseDown={handleMouseDown}
                                >
                                    <SendIcon className={classes.send} />
                                </span>


                            </div>
                        </>
                    )}
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={Boolean(error)}
                autoHideDuration={3000}
                onClose={() => setError("")}
                message={error}

            />
        </>
    );


}

export default Message;