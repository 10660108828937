import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import { configDevelopment, configProduction } from "config";
const config = window.location.hostname.includes("chef.stovecook") ? configProduction : configDevelopment;
firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.firestore();


export const createUser = async (email, password) => {
    const UserCredential = await auth.createUserWithEmailAndPassword(email, password);
    return UserCredential
}

export const signIn = async (email, password) => {
    const UserCredential = await auth.signInWithEmailAndPassword(email, password);
    return UserCredential
}

export const authStateChanged = async () => {
    let user = await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => resolve(user), error => reject(error));
    })
    return user;
}

export const getCurrentUser = async () => {
    let user = auth.currentUser;
    return user;
}
export const getCredential = async (email, password) => {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    return credential
}

export const signOut = async () => {
    await auth.signOut();
    return;
}


export const getAllTransactions = async (chefId) => {
    let transactionsDocs = [];
    let transactionsquery = db.collection("transactions").where("chefId", "==", chefId);
    let transactionsSnapshot = await transactionsquery.get();
    transactionsSnapshot.forEach(documentSnapshot => transactionsDocs.push(documentSnapshot.data()));
    return transactionsDocs;
}

export const getChefByEmail = async (email) => {
    let chefDocs = []
    let chefquery = db.collection("chefs").where("email", "==", email.trim().toLowerCase());
    let chefSnapshot = await chefquery.get();
    chefSnapshot.forEach(documentSnapshot => chefDocs.push(documentSnapshot.data()));
    return chefDocs[0];
}


export const getUser = async (userId) => {
    let userRef = db.collection("users").doc(userId);
    let userSnapshot = await userRef.get();
    let userDocs = userSnapshot.data();
    return userDocs;
}

export const getUserPhotoURL = async (email) => {
    let userPhotoURL = [];
    let userquery = db.collection("users").where("email", "==", email);
    let userSnapshot = await userquery.get();
    userSnapshot.forEach(documentSnapshot => userPhotoURL.push(documentSnapshot.get("photoURL")));
    return userPhotoURL[0];
}

export const getRecipes = async (recipeId) => {
    let recipesRef = db.collection("recipes").doc(recipeId);
    let recipesSnapshot = await recipesRef.get();
    let recipesDocs = recipesSnapshot.data();
    return recipesDocs;
}