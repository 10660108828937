import React, { useEffect, useContext, useState } from "react";
import Layout from "Layout";
import Pages from "Pages";
import PagesAuth from "PagesAuth/index";
import { consumer } from "State";


const App = () => {
  const { state } = useContext(consumer);
  return state.chef ? (
    <Layout>
      <Pages />
    </Layout>
  ) : (
      <PagesAuth />
    )
}

export default App;
