import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Transactions from "Pages/Transactions";
import Transaction from "Pages/Transaction";
import Messages from "Pages/Messages";
import Message from "Pages/Message";
import Profile from "Pages/Profile";

const App = () => {
    return (
        <Switch>
            <Route path="/transactions" exact={true}>
                <Transactions />
            </Route>
            <Route path="/transaction" exact={true}>
                <Transaction />
            </Route>
            <Route path="/messages" exact={true} >
                <Messages />
            </Route>
            <Route path="/message" exact={true} >
                <Message />
            </Route>
            <Route path="/profile" exact={true} >
                <Profile />
            </Route>
            <Redirect to="/transactions" />
        </Switch>
    );
}

export default App;