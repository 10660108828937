import React, { useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { createUser, getChefByEmail } from "Services/firebase";
import { consumer } from "State";
import Logo from "Assets/stove-white-logo.svg";

import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.4)",
        padding: theme.spacing(2, 2, 0, 2)
    },
    content: {
        width: "100%",
        maxWidth: "576px",
        margin: "auto",
        color: "#fff",
        textAlign: "center"
    },
    logo: {
        width: 130,
        height: "auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    header: {
        color: "#FF3539",
        fontWeight: 600,
        fontSize: "1.5em",
        textTransform: "capitalize"
    },
    textField: {
        color: "black !important",
        backgroundColor: "#fff !important",
        "&::placeholder": {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: "#BDBDBD !important"
        },
        "&:-ms-input-placeholder": {
            /* Internet Explorer 10-11 */
            color: "#BDBDBD"
        },
        "&::-ms-input-placeholder": {
            /* Microsoft Edge */
            color: "#BDBDBD"
        }
    },
    formHelperText: {
        textAlign: "center"
    },
    button: {
        marginTop: theme.spacing(2),
        color: "#fff",
        backgroundColor: "#FF3539",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#FF3539",
        },
        "&:focused": {
            color: "#fff",
            backgroundColor: "#FF3539",
        },
        "&:active": {
            color: "#fff",
            backgroundColor: "#FF3539",
        }
    },
    switch: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2)
    },
    switchText: {
        color: "#FF3539",
        cursor: "pointer"
    }
}));


const SignUp = () => {
    const classes = useStyles();
    const history = useHistory();
    const { action } = useContext(consumer);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState("");
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        reEnterPassword: ''
    });

    const handleChange = (prop) => (event) => {
        if (error) setError("");
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!values.email || !values.password || !values.reEnterPassword) {
                setError("All field are required");
                return;
            }
            if (values.password !== values.reEnterPassword) {
                setError("Password did not match");
                return;
            }
            if (error) setError("");
            setIsLoading(true);
            const chef = await getChefByEmail(values.email);
            if (chef) {
                let user = await createUser(values.email, values.password);
                if (user) {
                    action.setChef(chef);
                    setIsLoading(false);
                }
            } else {
                setError("No chef is associated with this email, contact support info@stovecook.com to create a chef account before sign-up");
                setIsLoading(false);
            }
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <img className={classes.logo} alt="Stove Logo" src={Logo} />
                <p className={classes.header}>Create Account</p>
                <FormControl size="small" fullWidth={true} variant="outlined" margin="normal">
                    <OutlinedInput
                        id="email"
                        autoComplete="off"
                        className={classes.textField}
                        placeholder="Email"
                        type='text'
                        value={values.email}
                        onChange={handleChange('email')}
                    />
                </FormControl>
                <FormControl size="small" fullWidth={true} variant="outlined" margin="normal">
                    <OutlinedInput
                        id="password"
                        autoComplete="off"
                        className={classes.textField}
                        placeholder="Password"
                        type={passwordVisible ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordVisible(passwordVisible => !passwordVisible)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl size="small" fullWidth={true} variant="outlined" margin="normal">
                    <OutlinedInput
                        id="reEnterPassword"
                        autoComplete="off"
                        className={classes.textField}
                        placeholder="Re-enter password"
                        type={passwordVisible ? 'text' : 'password'}
                        value={values.reEnterPassword}
                        onChange={handleChange('reEnterPassword')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordVisible(passwordVisible => !passwordVisible)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {error && (
                    <FormControl fullWidth={true} variant="outlined" margin="none">
                        <FormHelperText className={classes.formHelperText} error={true}>{error}</FormHelperText>
                    </FormControl>
                )}
                <Button onClick={handleSubmit} disabled={isLoading} fullWidth={true} className={classes.button} variant="contained">{isLoading ? <CircularProgress color="inherit" size={20} /> : "SIGN UP"}</Button>
                <p className={classes.switch}><span>I have an account?</span> <span onClick={() => history.push("/login")} className={classes.switchText}>Login</span></p>
            </div>
        </div>
    );

}

export default SignUp;