import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { initializeTwilioClient, getAllChannels } from "Services/twilio";
import { getUserPhotoURL } from "Services/firebase";
import { useHistory } from "react-router-dom";
import { consumer } from "State";
import Loading from "Components/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },
    text: {
        fontWeight: "bolder",
        fontSize: "large",
        textTransform: "capitalize"
    }
}));


const Messages = () => {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const { state, action } = useContext(consumer);

    useEffect(() => {
        (async () => {
            try {
                if (!state.channelsLoaded) {
                    setIsLoading(true);
                    const client = await initializeTwilioClient(state.chef.email);
                    const channels = await getAllChannels(client);
                    const channelWithUserPhoto = await Promise.all(channels.map(async (channel) => {
                        channel.userPhotoURL = await getUserPhotoURL(channel.uniqueName.split("-&-")[0]);
                        return channel
                    }))
                    action.setClient(client);
                    action.setChannels(channelWithUserPhoto);
                    action.setChannelsLoaded(true);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                setError(error.message);
            }
        })();
    }, [state.chef.email])

    const navigate = (channel) => {
        action.setChannel(channel);
        history.push(`/message`);
    }

    if (isLoading) {
        return <Loading />
    } else {
        return (
            <>
                <div className={classes.root}>
                    {!!state.channels.length ? (
                        <>
                            <List disablePadding={true}>
                                {state.channels.map((channel, idx) => (
                                    <ListItem key={idx} onClick={() => navigate(channel)} button={true} disableGutters={false} divider={true}>
                                        <ListItemAvatar>
                                            <Avatar alt="" src={channel.userPhotoURL} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primaryTypographyProps={{ className: classes.text }}
                                            primary={channel && channel.attributes && channel.attributes.userName} />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    ) : (
                            <Typography align="center" variant="body1" gutterBottom>
                                No Message recieved yet.
                            </Typography>
                        )
                    }
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={Boolean(error)}
                    autoHideDuration={3000}
                    onClose={() => setError("")}
                    message={error}

                />
            </>
        );
    }


}

export default Messages;