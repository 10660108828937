import React, { useEffect, useContext, useState } from "react";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import { getAllTransactions } from "Services/firebase";
import { useHistory } from "react-router-dom";
import { consumer } from "State";
import Loading from "Components/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    textSticky: {
        position: "sticky"
    },
    textContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    }
}));


const Transactions = () => {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const { state, action } = useContext(consumer);

    useEffect(() => {
        if (!state.transactionsLoaded) setIsLoading(true);
        (async () => {
            try {
                const transactions = await getAllTransactions(state.chef.chefId);
                action.setTransactions(transactions);
                action.setTransactionsLoaded(true);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setError(error.message);
            }
        })();
    }, [state.chef.chefId])

    const navigate = (transaction) => {
        action.setTransaction(transaction);
        history.push(`/transaction`);
    }

    if (isLoading) {
        return <Loading />
    } else {
        return (
            <div className={classes.root}>
                {!!state.transactions.length ? (
                    <>
                        <div className={classes.textSticky}>
                            <p className={classes.textContainer}>
                                <span className="textDark">Number of Transaction Recieved:</span>
                                <span className="textLight">{state.transactions.length}</span>
                            </p>
                            <p className={classes.textContainer}>
                                <span className="textDark">Total Amount Recieved:</span>
                                <span className="textLight">£{Number(state.transactions.reduce((total, currentTransaction) => (total + currentTransaction.amount), 0)).toFixed(2)}</span>
                            </p>
                            <Divider />
                        </div>
                        <List disablePadding={true}>
                            {state.transactions.map((transaction, idx) => (
                                <ListItem key={idx} onClick={() => navigate(transaction)} button={true} disableGutters={true} divider={true}>
                                    <ListItemText
                                        primary={`Recieved £${Number(transaction.amount).toFixed(2)}`}
                                        secondary={`Date: ${moment(new Date(transaction.timestamp)).format("ddd, DD MMM [at] HH:mm")}`} />
                                </ListItem>
                            ))}
                        </List>
                    </>
                ) : (
                        <Typography align="center" variant="body1" gutterBottom>
                            No Transactions recieved yet.
                        </Typography>
                    )
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={Boolean(error)}
                    autoHideDuration={3000}
                    onClose={() => setError("")}
                    message={error}

                />
            </div>
        );
    }


}

export default Transactions;


