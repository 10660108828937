import React, { useEffect, useContext, useState } from "react";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import { getUser, getRecipes } from "Services/firebase";
import { useHistory } from "react-router-dom";
import { consumer } from "State";
import stoveBackground from "Assets/stover-backgound.png";
import Loading from "Components/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    userBackground: {
        position: "relative",
        width: "100%",
        height: "100px",
        backgroundImage: `url(${stoveBackground})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginBottom: "60px"
    },
    userImage: {
        position: "absolute",
        width: "120px",
        height: "120px",
        bottom: "-50%",
        left: "calc(50% - 60px)"
    },
    textContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    }
}));



const Transactions = () => {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [transaction, setTransaction] = useState(null);
    const [user, setUser] = useState(null);
    const [recipe, setRecipe] = useState(null);
    const { state } = useContext(consumer);

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            try {
                const transaction = state.transaction;
                if (transaction) {
                    setTransaction(transaction);
                    if (transaction.type === "recipe") {
                        const [user, recipe] = await Promise.all([getUser(transaction.userId), getRecipes(transaction.recipeId)]);
                        setUser(user);
                        setRecipe(recipe);
                        setIsLoading(false);
                    } else {
                        const user = await getUser(transaction.userId);
                        setUser(user);
                        setIsLoading(false);
                    }
                } else {
                    history.push(`/transactions`);
                }
            } catch (error) {
                setIsLoading(false);
                setError(error.message);
            }
        })();
    }, [])

    if (isLoading) {
        return <Loading />
    } else {
        return (
            <>
                <div className={classes.root}>
                    {user && transaction && (
                        <>
                            <div className={classes.userBackground}>
                                <Avatar className={classes.userImage} alt="User" src={user.photoURL} />
                            </div>
                            <p className={classes.textContainer}>
                                <span className="textDark">Transaction ID:</span>
                                <span className="textLight"> {transaction.id}</span>
                            </p>
                            <p className={classes.textContainer}>
                                <span className="textDark">Amount:</span>
                                <span className="textLight"> £{Number(transaction.amount).toFixed(2)}</span>
                            </p>
                            <p className={classes.textContainer}>
                                <span className="textDark">Sender:</span>
                                <span className="textLight"> {user.displayName}</span>
                            </p>
                            <p className={classes.textContainer}>
                                <span className="textDark">Type:</span>
                                <span className="textLight capitalize"> {transaction.type}</span>
                            </p>
                            <p className={classes.textContainer}>
                                <span className="textDark">Date:</span>
                                <span className="textLight capitalize">{moment(new Date(transaction.timestamp)).format("ddd, DD MMM [at] HH:mm")}</span>
                            </p>
                        </>
                    )}
                    {recipe && (
                        <>
                            <p className={classes.textContainer}>
                                <span className="textDark">Recipe Title:</span>
                                <span className="textLight capitalize">{recipe.title}</span>
                            </p>
                            <p className={classes.textContainer}>
                                <span className="textDark">Recipe Thumbnail:</span>
                                <Avatar alt="recipe" src={recipe.thumbnail} />
                            </p>
                        </>
                    )}
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={Boolean(error)}
                    autoHideDuration={3000}
                    onClose={() => setError("")}
                    message={error}

                />
            </>
        );
    }


}

export default Transactions;


