import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const Loading = (props) => {
    const { three } = props;
    if (three) {
        return (
            <div>
                <Skeleton animation="wave" variant="text" width="90%" style={{ margin: "auto" }} />
                <Skeleton animation="pulse" variant="text" width="90%" style={{ margin: "auto" }} />
                <Skeleton animation="wave" variant="text" width="90%" style={{ margin: "auto" }} />
            </div>
        )
    } else {
        return (
            <>
                <div>
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="pulse" variant="rect" height={60} />
                </div>
                <div>
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="pulse" variant="rect" height={80} />
                </div>
                <div>
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="pulse" variant="rect" height={100} />
                </div>
                <div>
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="pulse" variant="rect" height={120} />
                </div>
                <div>
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="pulse" variant="rect" height={140} />
                </div>
                <div>
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="pulse" variant="rect" height={160} />
                </div>
            </>
        );
    }
}

export default Loading;