import axios from "axios";
import { Client } from "twilio-chat";


export const fetchToken = async (clientEmail) => {
    try {
        let response = await axios.post(`https://us-central1-stove-e851c.cloudfunctions.net/chatToken`, {
            id: clientEmail
        }, {
            "Content-Type": "application/json"
        })
        return response.data.token
    } catch (error) {
        console.log(error);
    }
}

export const initializeTwilioClient = async (clientEmail) => {
    let token = await fetchToken(clientEmail);
    if (token) {
        let client = await Client.create(token);
        client.on('tokenAboutToExpire', async function () {
            let token = await fetchToken(clientEmail);
            if (token) {
                client.updateToken(token);
            }
        });
        client.on('tokenExpired', async function () {
            let token = await fetchToken(clientEmail);
            if (token) {
                client.updateToken(token);
            }
        });
        return client;
    }
}


export const getAllChannels = async (client) => {
    let channels = await client.getSubscribedChannels();
    return channels.items.filter((channel) => channel.isPrivate === true)
}