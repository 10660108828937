import React, { useContext } from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { consumer } from "State";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textTransform: "capitalize"
    }
}));

export default function Header() {
    const classes = useStyles();
    const location = useLocation()
    const { action } = useContext(consumer)

    return (
        <AppBar className={classes.root} position="fixed" color="transparent">
            <Toolbar>
                <IconButton onClick={() => action.setSideBarIsOpen(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>{location.pathname.replace("/", "")}</Typography>
            </Toolbar>
        </AppBar>
    );
}